/* src/components/ScrollToTopButton.css */

.scrollToTopBtn {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.scrollToTopBtn:hover {
    background-color: #444;
}

.scrollToTopBtn svg {
    width: 20px;
    height: 20px;
}
