html {
  scroll-behavior: smooth;
}

/* Add some padding to the top of sections if needed to avoid content being hidden behind a fixed header */
section {
  padding-top: 60px;
  margin-top: -60px;
}

/* Ensure global styles don't conflict */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Sora', sans-serif;
}
