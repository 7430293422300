/* Reset some default styles */
body, h1, h2, h3, p, ul, li, a {
    text-decoration: none;
  }
  
  /* Header */
  .header {
    display: flex;
    align-items: flex-start;
    padding: 24px 80px;
    width: 100%;
    height: 104px;
    background: #f8f8f8;
  }
  
  /* Container */
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }
  
  /* Logo */
  .logo {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 140px;
    height: 40px;
  }
  
  .logo1 {
    width: 70px;
    height: 50px;
  }
  
  .logo-text {
    font-family: 'Sora', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
  }
  
  /* Taskbar */
  .taskbar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    height: 24px;
  }
  
  .nav-item {
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
  }
  
  .nav-item a {
    text-transform: capitalize;
    color: #000000;
  }
  
  /* Button */
  /* .button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
    gap: 8px;
    width: 153px;
    background: #000000;
    border-radius: 4px;
  } */
  
  .resume {
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  .download {
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border-radius: 50%;
  }
  
  /* Header.css */

.resume-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  gap: 8px;
}

.resume-link:hover {
  background-color: #333;
}

.download-icon {
  margin-left: 8px;
}
