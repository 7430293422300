/* Contact Section */
.contact-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 80px;
  width: 100%;
  background: #FFFFFF;
}

/* Container */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  gap: 40px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

/* Heading */
.contact-heading {
  font-family: 'Sora', sans-serif;
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  color: #000000;
  text-align: center;
}

/* Contact Information */
.contact-info {
  font-family: 'Sora', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #71717A;
  margin-bottom: 20px;
}

/* Email Link */
.contact-email {
  font-family: 'Sora', sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #000000;
  text-decoration: none;
  transition: color 0.3s;
  text-align: left;
}

.contact-email:hover {
  color: #007bff;
}

/* Phone Number */
.contact-phone {
  font-family: 'Sora', sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #000000;
}
