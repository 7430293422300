/* Footer Section */
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    background-color: #000000;
    color: #FFFFFF;
    width: 100%;
  }
  
  /* Footer Container */
  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 80px;
  }
  
  /* Logo */
  .footer-logo {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .footer-logo img {
    height: 40px;
    width: auto;
  }
  
  .footer-logo-text {
    font-weight: 600;
    font-size: 20px;
  }
  
  /* Footer Links */
  .footer-links {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-left: 150px;
  }
  
  .footer-link {
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  /* Copyright */
  .footer-copyright {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #71717A;
  }
  