@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;800&display=swap');


.skills-section {
  padding: 60px 20px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.skills-container {
  max-width: 1200px;
  width: 100%;
  text-align: center;
}

.skills-title {
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 60px;
  color: #000;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 40px;
}

.skill-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.skill-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.skill-icon {
  font-size: 48px;
  margin-bottom: 20px;
  color: #333;
}

.skill-item p {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}
